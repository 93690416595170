export default class SortedMap<K, V> implements Map<K, V> {
  private _compare: (_a: K, _b: K) => number;
  private _keys: K[] = [];
  private _values: V[] = [];

  private _from = 0;
  private _to = -1;

  constructor(compare: (_a: K, _b: K) => number) {
    this._compare = compare;
  }

  private to(): number {
    return this._to !== -1 ? this._to : this._keys.length;
  }

  private _arrayInsertAt<T>(arr: T[], item: T, index: number): T[] {
    return arr.splice(index, 0, item);
  }

  public has(key: K): boolean {
    const index: number = this.binaryClosestSearch(this._keys, this._from, this.to(), key);

    return this._keys[index] == key;
  }

  get size(): number {
    return this.to() - this._from;
  }

  public forEach(callback: (_value: V, _key: K, _map: Map<K, V>) => void): void {
    for (let i: number = this._from, to: number = this.to(); i < to; ++i)
      callback(this._values[i], this._keys[i], this);
  }

  public putAll(map: Map<K, V>): void {
    map.forEach((v: V, k: K) => this.set(k, v));
  }

  public set(key: K, value: V): this {
    let index: number = this.binaryClosestSearch(this._keys, this._from, this.to(), key);
    if (index < this._from) {
      index = this._from;
    } else if (this._keys[index] == key) {
      this._values[index] = value;

      return this;
    }

    this._arrayInsertAt(this._keys, key, index);
    this._arrayInsertAt(this._values, value, index);

    return this;
  }

  public get(key: K): V | undefined {
    const index: number = this.binaryClosestSearch(this._keys, this._from, this.to(), key);

    return this._keys[index] === key ? this._values[index] : undefined;
  }

  public delete(key: K): boolean {
    const index: number = this.binaryClosestSearch(this._keys, this._from, this.to(), key);
    if (this._keys[index] !== key) return false;

    this._keys.splice(index, 1);
    this._values.splice(index, 1);

    return true;
  }

  public subMap(from: K, to: K): SortedMap<K, V> {
    const subMap: SortedMap<K, V> = new SortedMap<K, V>(this._compare);
    subMap._keys = this._keys;
    subMap._values = this._values;
    const _to: number = this.to();
    subMap._from = this.binaryClosestSearch(this._keys, this._from, _to, from);
    subMap._to = this.binaryClosestSearch(this._keys, this._from, _to, to);

    return subMap;
  }

  public clear(): void {
    this._keys = [];
    this._values = [];
  }

  public keys(): IterableIterator<K> {
    let index: number = this._from;
    const to: number = this.to();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: SortedMap<K, V> = this;

    return {
      [Symbol.iterator](): IterableIterator<K> {
        return this;
      },
      next(): IteratorResult<K> {
        if (index === to) return { value: null, done: true };

        return { value: self._keys[index++], done: false };
      },
    };
  }

  public values(): IterableIterator<V> {
    let index: number = this._from;
    const to: number = this.to();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: SortedMap<K, V> = this;

    return {
      [Symbol.iterator](): IterableIterator<V> {
        return this;
      },
      next(): IteratorResult<V> {
        if (index === to) return { value: null, done: true };

        return { value: self._values[index++], done: false };
      },
    };
  }

  public entries(): IterableIterator<[K, V]> {
    let index: number = this._from;
    const to: number = this.to();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: SortedMap<K, V> = this;

    return {
      [Symbol.iterator](): IterableIterator<[K, V]> {
        return this;
      },
      next(): IteratorResult<[K, V]> {
        if (index === to) return { value: null, done: true };

        ++index;

        return { value: [self._keys[index], self._values[index]], done: false };
      },
    };
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.entries();
  }

  get [Symbol.toStringTag](): string {
    let str: string = '';
    this.forEach((v: V, k: K) => (str += ',' + k + '=' + v));

    return '{' + str.substring(1) + '}';
  }

  private binaryClosestSearch(a: K[], fromPosition: number, toPosition: number, key: K): number {
    let from = fromPosition;
    let to = toPosition;
    for (let mid: number; from < to; ) {
      // [N]
      mid = Math.floor((from + to) / 2);
      const c: number = this._compare(key, a[mid]);
      if (c < 0) to = mid;
      else if (c > 0) from = mid + 1;
      else return mid;
    }

    return Math.floor((from + to) / 2);
  }
}
