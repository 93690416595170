import React from 'react';
import { rscGlobalAlert } from 'data';
import { useRecoilState } from 'recoil';
import { Severity } from 'types';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const GlobalAlert = (): React.ReactElement => {
  const [globalAlert, setGlobalAlert] = useRecoilState(rscGlobalAlert);

  if (!globalAlert) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!globalAlert}
      autoHideDuration={5000}
      onClose={(): void => setGlobalAlert(null)}
    >
      <Alert severity={globalAlert?.severity || Severity.Error} variant='filled'>
        {globalAlert?.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;
