import { rscMyCorrelationIds } from 'data';
import { rssIsStationOzCapable } from 'data/store/stationStore';
import { atom, DefaultValue, RecoilState, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import {
  ByUser,
  IOzStation,
  IOzStations,
  MyCorrelationIdMap,
  OpticalZoomData,
  OzStationsStatus,
  OzStatus,
  Station,
  StationCamera,
  STATUS_MODE,
  UpdateOzStationParams,
  UpdateOzStationStatusParams,
  UpdateStationOzCorrelationIdParams,
  UpdateStationOzPeriodParams,
  UpdateStationOzStartTimeParams,
} from 'types';
import { assertValueDefined, deleteObjectField, isOnPageWithOzPlayer, stationWithOpticalZoom } from 'utils';

import {
  clearFulfilledPendingPtzApiCalls,
  rspClearPendingPtzApiCallsOnStations,
  rspOzControlNotification,
} from '../index';

/**
 * a map of sessions in OZ mode
 * station id => OZ session info
 */
export const rspOzStations = atom<IOzStations>({
  key: 'rspOzStations',
  default: {},
});

export const rspEndingOzSession = atom<boolean>({
  key: 'rspEndingOzSession',
  default: false,
});

export const rspCameraStatusTs = atom<number>({
  key: 'rspCameraStatusTs',
  default: 0,
});

/**
 * Whether the full-screen station is in OZ mode
 * @note even if the station is in OZ mode, it can be full-screen, normal player sometimes.
 */
export const rspFullScreenInOz = atom<boolean>({
  key: 'rspFullScreenInOz',
  default: false,
});

/**
 * Station id of full-screen normal player.
 * @note even if the station is in OZ mode, it can be full-screen normal player sometimes.
 */
export const rspFullScreenStationId = atom<number>({
  key: 'rspFullScreenStationId',
  default: 0,
});

/**
 * a map of stations to oz sessions statuses
 * - station id => oz status enum
 */
export const rspOzStationsStatus = atom<OzStationsStatus>({
  key: 'rspOzStationsStatus',
  default: {},
});

/**
 * @returns the status of the OZ session for the station with the given id
 * - types include: Started, Expired, NoImages, Ended, EndedByOthers, Void
 */
export const rspGetOzStationsStatus: (param: number) => RecoilValueReadOnly<OzStatus> = selectorFamily<
  OzStatus,
  number
>({
  key: 'rspGetOzStationsStatus',
  get:
    (stationId) =>
    ({ get }): OzStatus => {
      const ozStationsStatus: OzStationsStatus = get(rspOzStationsStatus);
      const status: OzStatus = ozStationsStatus[stationId];

      return status || OzStatus.Void;
    },
});

/**
 * Gets startTimestamp of an OZ session for a stationId
 */
export const rspGetStationOzStartTime: (param: number) => RecoilValueReadOnly<number> = selectorFamily<number, number>({
  key: 'rspGetStationOzStartTime',
  get:
    (stationId) =>
    ({ get }): number => {
      const ozStations: IOzStations = get(rspOzStations);
      const ozStation: IOzStation = ozStations[stationId];

      return ozStation?.startTime || 0;
    },
});

/**
 * @returns the `lastOzSequence` of an OZ session for a specific stationId
 * @note If OZ session is created by the current user, the OZ images must be with timestamp after lastOzSequence[1].
 *     Otherwise, it is started by another user, there are 2 cases:
 *     1. OZ is just started, no OZ images yet
 *     2. OZ is running, we have OZ images for this session
 *    We are not able to distinguish these 2 cases, so we just use lastOzSequence[0] to fetch OZ images.
 *    Then filter out OZ images by correlation-id.
 *    @jira https://panoai.atlassian.net/browse/RD-1134
 */
export const rspGetStationLastOzSequence: (param: number) => RecoilValueReadOnly<number> = selectorFamily<
  number,
  number
>({
  key: 'rspGetStationOzStartTime',
  get:
    (stationId) =>
    ({ get }): number => {
      const ozStations: IOzStations = get(rspOzStations);
      const ozStation: IOzStation = ozStations[stationId];
      if (!ozStation?.lastOzSequence) {
        console.debug('Oz station lastOzSequence is not set.');

        return 0;
      }

      return ozStation?.lastOzSequence[ozStation?.byUser === ByUser.Me ? 1 : 0];
    },
});

/**
 * @returns the `period` of an OZ session
 */
export const rspGetStationOzPeriod: (param: number) => RecoilValueReadOnly<number> = selectorFamily<number, number>({
  key: 'rspGetStationOzPeriod',
  get:
    (stationId) =>
    ({ get }): number => {
      const ozStations: IOzStations = get(rspOzStations);
      const ozStation: IOzStation = ozStations[stationId];

      return ozStation?.period;
    },
});

/**
 * Update correlation id of the OZ session
 */
export const rspUpdateStationOzCorrelationId: RecoilState<UpdateStationOzCorrelationIdParams> =
  selector<UpdateStationOzCorrelationIdParams>({
    key: 'rspUpdateStationOzCorrelationId',
    get: () => null, // @note never call `get`
    set: ({ get, set }, params) => {
      if (params instanceof DefaultValue) {
        return;
      }

      const { stationId, correlationId } = params;
      const ozStations: IOzStations = get(rspOzStations);
      const oz: IOzStation = ozStations[stationId];
      // If OZ is not on, or the start time is the same, do nothing
      if (!oz || oz.correlationId === correlationId) {
        return;
      }

      set(rspOzStations, {
        ...ozStations,
        [stationId]: {
          ...oz,
          correlationId,
        },
      });
    },
  });

/**
 * Setter for updating the Start Time of an OZ session
 */
export const rspUpdateStationOzStartTime: RecoilState<UpdateStationOzStartTimeParams> =
  selector<UpdateStationOzStartTimeParams>({
    key: 'rspUpdateStationOzStartTime',
    get: () => null, // @note never call `get`
    set: ({ get, set }, params) => {
      if (params instanceof DefaultValue) {
        return;
      }

      const { stationId, startTime } = params;
      const ozStations: IOzStations = get(rspOzStations);
      const oz: IOzStation = ozStations[stationId];

      // If OZ is not on, or the start time is the same, do nothing
      if (!oz || oz.startTime === startTime) {
        return;
      }

      set(rspOzStations, {
        ...ozStations,
        [stationId]: {
          ...oz,
          startTime,
        },
      });
    },
  });

/**
 * Add a new OZ Session
 */
export const rspAddOzStation = selector<IOzStation>({
  key: 'rspOzStations/add',
  get: () => null, // @note never use `get`
  set: ({ get, set }, newOzStation) => {
    if (newOzStation instanceof DefaultValue) {
      return;
    }

    const hasOzPermission: boolean = !!newOzStation?.zoom;

    if (!hasOzPermission) return;

    const ozStations: IOzStations = get(rspOzStations);
    set(rspOzStations, {
      ...ozStations,
      [newOzStation.stationId]: newOzStation,
    });
    set(rspOzControlNotification, null);
    clearFulfilledPendingPtzApiCalls();

    // If currently viewing full-screen normal player, switch to OZ player
    const fullScreenStationId: number = get(rspFullScreenStationId);
    if (fullScreenStationId === newOzStation.stationId) {
      set(rspFullScreenInOz, true);
    }
  },
});

/**
 * Update Session Period for a given station
 */
export const rspUpdateStationOzPeriod = selector<UpdateStationOzPeriodParams>({
  key: 'rspUpdateStationOzPeriod',
  get: () => null, // @note never call `get`
  set: ({ get, set }, params) => {
    if (params instanceof DefaultValue) {
      return;
    }

    const { stationId, ozImageData } = params;
    const ozStations: IOzStations = get(rspOzStations);
    const oz: IOzStation = ozStations[stationId];
    const lastImageMeta: OpticalZoomData = ozImageData[ozImageData.length - 1];
    if (!oz || lastImageMeta.length < 5 || !get(rssIsStationOzCapable(stationId))) {
      return;
    }

    set(rspOzStations, {
      ...ozStations,
      [stationId]: {
        ...oz,
        period: lastImageMeta[5],
      },
    });
  },
});

// remove a OZ session
export const rspRemoveOzStation = selector<number>({
  key: 'rspOzStations/remove',
  get: () => null, // @note never use `get`
  set: ({ get, set }, stationId) => {
    if (stationId instanceof DefaultValue) {
      return;
    }

    const ozStations: IOzStations = get(rspOzStations);
    set(rspOzStations, deleteObjectField<IOzStation>(ozStations, stationId));
    clearFulfilledPendingPtzApiCalls();
    set(rspOzControlNotification, null);
    set(rspClearPendingPtzApiCallsOnStations, stationId);
    // OZ session ended, no OZ for full-screen.
    // But we may still have `rspFullScreenStationId` to show normal player in full-screen
    set(rspFullScreenInOz, false);
  },
});

/**
 * For a list of updated ECs, either start or end OZ sessions of them
 */
export const rspUpdateOzStationsByEcs = selector<Station[]>({
  key: 'rspUpdateOzStationsByEcs',
  get: () => null /** @note never call `get` */,
  set: ({ get, set }, stations) => {
    if (stations instanceof DefaultValue) {
      return;
    }

    const ozStations: IOzStations = get(rspOzStations);
    const myCorrelationIds: MyCorrelationIdMap = get(rscMyCorrelationIds);
    const filteredStations: Station[] = stations.filter(stationWithOpticalZoom);
    filteredStations.forEach((station) => {
      const manual: StationCamera = station.cameras.find((camera) => camera.state === STATUS_MODE.MANUAL);
      const ozStation: IOzStation = ozStations[station?.id];

      // station in OZ already, launch it on FE.
      if (manual && station.lastOzSequence?.length && !ozStation) {
        const byMe: boolean = manual.correlationId && !!myCorrelationIds?.[manual.correlationId];
        assertValueDefined(`get /ecs EC-${station.id} period`, manual.period);
        set(rspAddOzStation, {
          stationId: station.id,
          loading: true,
          tiltMax: station.cameraType.tiltMax,
          tiltMin: station.cameraType.tiltMin,
          zoomMax: station.cameraType.zoomMax,
          period: manual.period,
          lastOzSequence: station.lastOzSequence,
          // the OZ session starts, but maybe no image yet, need to set startTime after the first image comes
          startTime: 0,
          pan: manual.pan,
          tilt: manual.tilt,
          zoom: manual.zoom,
          correlationId: manual.correlationId,
          byUser: byMe ? ByUser.Me : ByUser.Others,
        });
        // Show OZ start modal only if the OZ is not started by the current user (in current session).
        if (!byMe) {
          set(rspUpdateOzStationsStatus, {
            stationId: station.id,
            status: OzStatus.Started,
          });
        }
      }

      // @jira RD-353 [OZ BETA] OZ session doesn't end in station details page when it was first ended on incident details page
      const noManual: boolean = station.cameras.every((camera) => camera.state !== STATUS_MODE.MANUAL);
      if (noManual && ozStation) {
        set(rspRemoveOzStation, station.id);
        set(rspClearPendingPtzApiCallsOnStations, station.id);
        set(rspFullScreenInOz, false);
        const ozStatus: OzStatus = get(rspGetOzStationsStatus(station.id));
        /**
         * @note The OZ session may be expired or ended by user himself, and user has not clicked the OK button.
         * In this case, we do not confuse user to show EndedByOthers modal.
         */
        if (isOnPageWithOzPlayer() && (ozStatus === OzStatus.Void || ozStatus === OzStatus.Started)) {
          set(rspUpdateOzStationsStatus, {
            stationId: station.id,
            status: OzStatus.EndedByOthers,
          });
        } else {
          /**
           * If user is not on OZ page, station starts, the status is set to `Started`, then it is ended,
           * we need to reset it to be `Void`. Otherwise, when user enters an OZ page, the `Started` modal
           * will show up.
           */
          set(rspUpdateOzStationsStatus, {
            stationId: station.id,
            status: OzStatus.Void,
          });
        }
      }
    });
  },
});

/**
 * Update the information about an OZ session
 */
export const rspUpdateOzStation = selector<UpdateOzStationParams>({
  key: 'rspOzStations/update',
  get: () => null, // @note never use `get`
  set: ({ get, set }, periodParams) => {
    if (periodParams instanceof DefaultValue) {
      return;
    }

    const ozStations: IOzStations = get(rspOzStations);
    const { stationId, ...rest } = periodParams;

    if (!ozStations[stationId]) {
      return;
    }

    set(rspOzStations, {
      ...ozStations,
      [stationId]: {
        ...ozStations[stationId],
        ...rest,
      },
    });
  },
});

/**
 * @returns {boolean} - wether or not a give station is in OZ mode
 */
export const rspIsStationInOz: (param: number) => RecoilValueReadOnly<boolean> = selectorFamily<boolean, number>({
  key: 'rspIsStationInOz',
  get:
    (stationId) =>
    ({ get }): boolean => {
      const ozStations: IOzStations = get(rspOzStations);

      return !!ozStations[stationId];
    },
});

/**
 * @returns {boolean} - returns true if this user started the active OZ session for a given stationId
 */
export const rspIsStationInOzByMe: (param: number) => RecoilValueReadOnly<boolean> = selectorFamily<boolean, number>({
  key: 'rspIsStationInOzByMe',
  get:
    (stationId) =>
    ({ get }): boolean => {
      const ozStations: IOzStations = get(rspOzStations);

      return ozStations[stationId] && ozStations[stationId].byUser === ByUser.Me;
    },
});

/**
 * update info of Oz status of a station
 */
export const rspUpdateOzStationsStatus: RecoilState<UpdateOzStationStatusParams> =
  selector<UpdateOzStationStatusParams>({
    key: 'rspOzStationsStatus/update',
    get: () => null, // @note never use `get`
    set: ({ get, set }, params) => {
      if (params instanceof DefaultValue) {
        return;
      }

      const ozStationsStatus: OzStationsStatus = get(rspOzStationsStatus);
      const { stationId, status } = params;
      set(rspOzStationsStatus, {
        ...ozStationsStatus,
        [stationId]: status,
      });
    },
  });
