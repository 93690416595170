import { useEffect } from 'react';
import { initApiToken } from 'config/api';
import { rsaFrozenUser, rsaSecuritySettingsChanged, rsaUser, rscDataConflictError, rscGlobalAlert } from 'data';
import useLogout from 'hooks/useLogout';
import useStations from 'hooks/useStations';
import { useLocation } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getIsSharedPage } from 'router/Router.helpers';

/**
 * When a user is auth'd and visits a non-shared page in the application.
 * This unfreezes the user, and updates the accessToken to the active user
 *
 * @param pathName
 */
export const useUnfreezeUser = () => {
  const location = useLocation();
  const [user, setUser] = useRecoilState(rsaUser);
  const [frozenUser, setFrozenUser] = useRecoilState(rsaFrozenUser);
  const { refreshStations } = useStations();
  const securitySettingsChanged = useRecoilValue(rsaSecuritySettingsChanged);
  const isOnSharedPage = getIsSharedPage(location.pathname);
  const logout = useLogout({ callLogoutApi: false });
  const setGlobalAlert = useSetRecoilState(rscGlobalAlert);
  const setDataConflictError = useSetRecoilState(rscDataConflictError);

  useEffect(() => {
    if (!isOnSharedPage && user && frozenUser && !securitySettingsChanged) {
      initApiToken(logout, frozenUser, setGlobalAlert, setDataConflictError);
      setUser(frozenUser);
      setFrozenUser(null);
      refreshStations(true);
    } else {
      initApiToken(logout, user, setGlobalAlert, setDataConflictError);
    }
  }, [
    user,
    frozenUser,
    isOnSharedPage,
    setUser,
    setFrozenUser,
    securitySettingsChanged,
    refreshStations,
    logout,
    setGlobalAlert,
    setDataConflictError,
  ]);
};
