import Sha256 from 'sha256';
import { CountryPhone, PhoneCode } from 'types';

export const IS_DEV: boolean =
  process.env.NODE_ENV === 'development' ||
  window.location.hostname === 'localhost' ||
  window.location.hostname === '360.dev.pano.ai';
export const IS_PROD: boolean = process.env.NODE_ENV === 'production';
export const APP_ENV =
  {
    '360.dev.pano.ai': 'dev',
    '360.stage.pano.ai': 'stage',
    '360.pano.ai': 'prod',
  }[window.location.hostname] || 'local';

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const BASE_API_URL: string = process.env.REACT_APP_BASE_API_URL;
export const MAPBOX_BASE_API_URL: string = process.env.REACT_APP_MAPBOX_BASE_API;
export const GEOJSON_SERVER: string = process.env.REACT_APP_GEOJSON_SERVER;
export const TRACKING_ENABLED: boolean = process.env.REACT_APP_TRACKING_ENABLED === '1';
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN;
/** Whether to use cache for API calls */
export const API_CACHE_ENABLED: boolean = process.env.REACT_APP_API_CACHE_ENABLED === '1';
// Mobx store expires in 15 minutes
export const STORE_CACHE_EXPIRES: number = 60 * 1000 * 15;

/**
 * @deprecated - use '@mui/material/useMediaQuery';`
 */
export const MOBILE_QUERY = '(max-width: 600px)';
/**
 * @deprecated - use '@mui/material/useMediaQuery';`
 */
export const TABLET_QUERY = '(max-width: 900px)';

export const HAS_SECURITY_SETTINGS_CHANGED = 'HAS_SECURITY_SETTINGS_CHANGED';
export const USER_ORG_INFO_FIELD = 'USER_ORG_INFO';
export const META_FIELD = 'META_FIELD';
export const USER_FIELD = 'PANO_USER';
export const USER_PAGE_WITH_FULL_AUTH_FROZEN = 'USER_PAGE_WITH_FULL_AUTH_FROZEN';
export const USER_MAP_LAYERS_VISIBILITY = 'USER_MAP_LAYERS_VISIBILITY';
export const USER_WEATHER_MAP_LAYER_SELECTION = 'USER_WEATHER_MAP_LAYER_SELECTION';
export const USER_MAP_INFO_FIELD = 'USER_MAP_INFO';
export const USER_EMAIL_FIELD = 'USER_EMAIL';
export const IDP_DISCOVERY_EMAIL = 'IDP_DISCOVERY_EMAIL';
export const FROZEN_USER_FIELD = 'FROZEN_PANO_USER';
export const REDIRECT_URL = 'REDIRECT_URL';
export const SECRET_ANONYMOUS = Sha256('__anonymous__');
export const IS_PIC_WORKFLOW_REQUESTED = 'IS_PIC_WORKFLOW_REQUESTED';
export const IS_PIC_USER_AVAILABLE = 'IS_PIC_USER_AVAILABLE';
export const IS_TASK_ACKNOWLEDGED = 'IS_TASK_ACKNOWLEDGED';
export const HAS_USER_BEEN_REDIRECTED_TO_TASK = 'HAS_USER_BEEN_REDIRECTED_TO_TASK';
export const ACTIVE_TASK = 'ACTIVE_TASK';
export const USER_ACTIVITY = 'USER_ACTIVITY';

export const PAGINATION_PAGE_SIZE: number = 10;
export const PAGINATION_LIST_SIZE: number = 5;

export const TASK_POLLING_INTERVAL = 1000 * 10;

export const METERS_TO_MILE = 0.00062137;

export const countryPhones: CountryPhone = {
  au: {
    code: '61',
    mask: '499 999 999',
    name: 'Australia',
    format: (x = '') => {
      if (!x) {
        return '';
      }
      const parts: RegExpMatchArray = String(x)
        .replace(/\D/g, '')
        .match(/(\d{2})(\d{3})(\d{3})(\d{3})/);
      // Make sure no index overflow
      if (!parts || parts.length <= 4) {
        return x;
      }

      return `+${parts[1]} (${parts[2]}) ${parts[3]}-${parts[4]}`;
    },
  },
  us: {
    code: '1',
    mask: '(999) 999-9999',
    name: 'United States',
    format: (x = '') => {
      if (!x) {
        return '';
      }
      const parts: RegExpMatchArray = String(x)
        .replace(/\D/g, '')
        .match(/(\d)(\d{3})(\d{3})(\d{4})/);
      // Make sure no index overflow
      if (!parts || parts.length <= 4) {
        return x;
      }

      return `+${parts[1]} (${parts[2]}) ${parts[3]}-${parts[4]}`;
    },
  },
};

/** List of country codes */
export const countryCodes: string[] = Object.values(countryPhones).map((country) => country.code);

export const formatPhone = (phone: string): string => {
  const meta: PhoneCode = String(phone).indexOf('1') === 0 ? countryPhones.us : countryPhones.au;

  return meta.format(phone);
};

export const MAP_PAGES: {
  INCIDENTS: string;
  INCIDENT_DETAIL: string;
  STATIONS: string;
  STATION_DETAIL: string;
  WIDGET: string;
} = {
  INCIDENTS: 'incidents',
  INCIDENT_DETAIL: 'incidentDetail',
  STATIONS: 'stations',
  STATION_DETAIL: 'stationDetail',
  WIDGET: 'widget',
};

export const MAP_TYPES: { WIDGET: string; SCREEN: string } = {
  WIDGET: 'widget',
  SCREEN: 'screen',
};
