import { AxiosInstance } from 'axios';
import { geoApi, mapBoxApi } from 'config/api';
import { MapAsset, MapSearchResult } from 'types';

const MAPBOX_QUERY: string = `access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true&fuzzyMatch=true&country=US,AU,CA&language=en`;
export default class MapApi {
  mapBoxApi: AxiosInstance;

  constructor() {
    this.mapBoxApi = mapBoxApi;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.mapBoxApi = axiosInstance;
  }

  // Mapbox API to search location on map
  async apiGetSearchLocation(query: string): Promise<MapSearchResult> {
    try {
      const { data } = await this.mapBoxApi.get(`/mapbox.places/${query}.json?${MAPBOX_QUERY}`);

      return data;
    } catch (e) {
      return null;
    }
  }

  /**
   * @description Searches for assets nearby a given lat/long and search radius
   * **NOTE** DO NOT CALL THIS DIRECTLY FROM COMPONENT - USE RECOIL SELECTOR rsmFetchNearByAssets
   */
  async apiGetNearByAssets(
    /** Latitude of incident */
    lat: number,
    /** Longitude of incident */
    lon: number,
    /** Radius to search around from the point */
    r = 2,
    mapAssetDataSets: string[] = [],
  ): Promise<MapAsset[]> {
    if (!lat || !lon) {
      return null;
    }

    try {
      const { data } = await geoApi.get(
        `/${mapAssetDataSets.join('+')}/latest/geosearch.json?coords=${lat},${lon}&r=${r}`,
      );

      return data[Object.keys(data)[0]];
    } catch (e) {
      return null;
    }
  }
}
