import { countryPhones } from 'config/base';
import { INCIDENT_BAR_BORDER_WIDTH_SCALE, METERS_IN_KM } from 'config/constants';
import { computeDestinationPoint } from 'geolib';
import _get from 'lodash/get';
import proj4 from 'proj4';
import { GDACoordinates, Incident, IncidentCamera, LonLat, LonLatArray, PhoneCode, Station } from 'types';

/** Default precision for lat/lon (see https://en.wikipedia.org/wiki/Decimal_degrees) */
export const LAT_LON_DECIMAL_PLACES = 5;

/**
 * Get the country code from the country name.
 * @param country
 */
export const getCountryCodeFromCountry = (country: string): string => {
  const countryPhone: PhoneCode = _get(countryPhones, country.toLowerCase(), countryPhones['us']);

  return countryPhone.code;
};

/**
 * In form, a non-null phone with value just country code is considered empty.
 * @param phone
 */
export const phoneIsEmpty = (phone: string): boolean => {
  return !phone || !!Object.values(countryPhones).find((countryPhone) => countryPhone.code === phone);
};

// Camera can see 36 degree from the bearing (number is arbitrary).
export const CAMERA_IN_VIEW_CONE_ANGLE: number = 36;
// Error bounding range of the bearing
export const CAMERA_UNCERTAINTY_ANGLE: number = 0.5;

// Get angle based on mapzoom level.
// @todo remove the magic number.
export const getCameraUncertaintyAngleByMapZoom = (mapZoom: number): number => {
  if (mapZoom > 16) {
    return 0.001;
  }

  if (mapZoom > 13.5) {
    return 0.01;
  }

  if (mapZoom > 11) {
    return 0.1;
  }

  return CAMERA_UNCERTAINTY_ANGLE;
};

function roundAngle(angle: number): number {
  return (angle + 360) % 360;
}

function lngLatToArray(lngLat: { lon: number; lat: number }): LonLatArray {
  return [lngLat.lon, lngLat.lat];
}

/**
 * When drawing our cones, boxes and circles, we want to do them for the minimum visibility of each camera
 * @param station The station we want to get the minimum visibility for
 * @returns The smallest visibility for any camera for a station in meters
 */
export const getMinStationVisibilityInM = (station: Station) => {
  const visibilities = station?.cameras?.map((camera) => camera.visibility) || [20];

  return Math.min(...visibilities) * METERS_IN_KM;
};

/**
 * Calculates a new Lon Lat from the current Lon Lat at distance and bearing from the original
 * - This calculation happens with the [Haversine formula](https://en.wikipedia.org/wiki/Haversine_formula)
 * @param LonLat The lon lat of the start point
 * @param distance the distance away from the start point to get the new point
 * @param bearing the angle (0-360) to traverse to get the new point
 * @returns the LonLat of the new point
 */
export const computeDestinationPointLonLat = ({ lon, lat }: LonLat, distance: number, bearing: number) => {
  const { longitude, latitude } = computeDestinationPoint({ latitude: lat, longitude: lon }, distance, bearing);

  return {
    lon: longitude,
    lat: latitude,
  };
};

/**
 * @description The width in meters of an incident bar
 * @note this width ends up being doubled, as its technically the width to each side from center
 * @note Uses magic numbers to approximate appropriate width at different zoom levels
 */
export const getIncidentBarWidth = (zoom: number) => {
  return (zoom > 10 ? 800000 : 600000) / Math.pow(2, zoom);
};

/**
 * Gets the latitute and longitude of points on a rectangle to display on a map
 */
export const getRectanglePoints = ({
  lat,
  lon,
  start,
  end,
  bearing,
  width,
}: {
  /** centroid latitude */
  lat: number;
  /** centroid longitude */
  lon: number;
  /** distance in meters to start of rectangle from centroid */
  start: number;
  /** distance in meters to end of rectangle from centroid */
  end: number;
  /** bearing of rectangle midline from centroid */
  bearing: number;
  /** a scaling factor to determing the  */
  width: number;
}) => {
  const clockwiseBearing = bearing + 90;
  const counterClockwiseBearing = bearing - 90;

  const clockwisePoint = computeDestinationPointLonLat({ lat, lon }, width, clockwiseBearing);
  const counterClockwisePoint = computeDestinationPointLonLat({ lat, lon }, width, counterClockwiseBearing);

  const point1 = computeDestinationPointLonLat(counterClockwisePoint, start, bearing);
  const point2 = computeDestinationPointLonLat(clockwisePoint, start, bearing);
  const point3 = computeDestinationPointLonLat(clockwisePoint, end, bearing);
  const point4 = computeDestinationPointLonLat(counterClockwisePoint, end, bearing);

  return [point1, point2, point3, point4, point1].map(lngLatToArray);
};

/**
 * Gets the latitute and longitude of points on a triangle to display on a map
 */
export const getTrianglePoints = ({
  lat,
  lon,
  start,
  bearing,
  width,
}: {
  /** centroid latitude */
  lat: number;
  /** centroid longitude */
  lon: number;
  /** distance in meters to start of feature from centroid */
  start: number;
  /** bearing of feature midline from centroid */
  bearing: number;
  /** a scaling factor to determing the  */
  width: number;
}) => {
  const clockwiseBearing = bearing + 90;
  const counterClockwiseBearing = bearing - 90;

  const clockwisePoint = computeDestinationPointLonLat({ lat, lon }, width, clockwiseBearing);
  const counterClockwisePoint = computeDestinationPointLonLat({ lat, lon }, width, counterClockwiseBearing);
  const point1 = computeDestinationPointLonLat(clockwisePoint, start, bearing);
  const point2 = computeDestinationPointLonLat(counterClockwisePoint, start, bearing);
  const point3 = computeDestinationPointLonLat({ lat, lon }, start + width, bearing);

  return [point1, point2, point3, point1].map(lngLatToArray);
};

/**
 * Gets the latitute and longitude of points on a chevron to display on a map
 */
export const getChevronPoints = ({
  lat,
  lon,
  start,
  bearing,
  width,
}: {
  /** centroid latitude */
  lat: number;
  /** centroid longitude */
  lon: number;
  /** distance in meters to start of feature from centroid */
  start: number;
  /** bearing of feature midline from centroid */
  bearing: number;
  /** the width of each triangle */
  width: number;
}) => {
  const clockwiseBearing: number = bearing + 90;
  const counterClockwiseBearing = bearing - 90;

  const clockwisePoint = computeDestinationPointLonLat({ lat, lon }, width, clockwiseBearing);
  const counterClockwisePoint = computeDestinationPointLonLat({ lat, lon }, width, counterClockwiseBearing);
  const chevronWidth = width / INCIDENT_BAR_BORDER_WIDTH_SCALE;
  const hypotenuse = Math.sqrt(2) * chevronWidth;

  const point1 = computeDestinationPointLonLat(clockwisePoint, start, bearing);
  const point2 = computeDestinationPointLonLat({ lat, lon }, start + width, bearing);
  const point3 = computeDestinationPointLonLat(counterClockwisePoint, start, bearing);

  const point4 = computeDestinationPointLonLat(point3, chevronWidth, bearing + 135);
  const point5 = computeDestinationPointLonLat(point2, hypotenuse, bearing - 180);
  const point6 = computeDestinationPointLonLat(point1, chevronWidth, bearing - 135);

  return [point1, point2, point3, point4, point5, point6, point1].map(lngLatToArray);
};

// Polygon points to draw Incident Direction Line
export function getRectPointsLatLng(
  incident: Incident,
  station: Station,
  incidentCamera: IncidentCamera,
  zoom: number,
): LonLatArray[] {
  const rectWidth: number = getIncidentBarWidth(zoom);
  const distance = getMinStationVisibilityInM(station);
  const { bearing }: IncidentCamera = incidentCamera;
  const { lat: cameraLat, lon: cameraLng }: Station = station;
  if (bearing === null) {
    console.warn('No bearing found');
  }

  const point1Bearing: number = roundAngle(bearing + 90);
  const point2Bearing: number = roundAngle(bearing - 90);
  const point1 = computeDestinationPointLonLat({ lat: cameraLat, lon: cameraLng }, rectWidth, point1Bearing);

  const point2 = computeDestinationPointLonLat({ lon: cameraLng, lat: cameraLat }, rectWidth, point2Bearing);
  const point3 = computeDestinationPointLonLat({ lon: point2.lon, lat: point2.lat }, distance, bearing);
  const point4 = computeDestinationPointLonLat({ lon: point1.lon, lat: point1.lat }, distance, bearing);

  return [point1, point2, point3, point4, point1].map(lngLatToArray);
}

/**
 * @returns The Polygon points to draw Incident Uncertainty Cone and Incident Direction Line map layer
 */
export function getPolygonPointsLatLng({
  incidentCamera,
  station,
  coneAngle = CAMERA_IN_VIEW_CONE_ANGLE,
  canvasBearing = null,
}: {
  /** The camera associated with the incident */
  incidentCamera?: IncidentCamera;
  /** The station we're creating the polygon for*/
  station: Station;
  /** The number of degrees of the apex angle of the cone. ie. The inside angle of the pointy part of the cone */
  coneAngle?: number;
  /** The bearing of the line to draw (0-360 degrees) */
  canvasBearing?: number;
}): LonLatArray[] {
  const halfAngleOfAreaInView: number = coneAngle / 2;
  const visibility = getMinStationVisibilityInM(station);
  // @doc https://juejin.cn/post/6844904179387858951
  const distance = visibility / Math.cos(((2 * Math.PI) / 360) * halfAngleOfAreaInView);

  const { lat: cameraLat, lon: cameraLng } = station;
  const bearingAngle: number = canvasBearing || incidentCamera?.bearing;

  return [
    { lat: cameraLat, lon: cameraLng },
    computeDestinationPointLonLat(
      { lon: cameraLng, lat: cameraLat },
      distance,
      roundAngle(bearingAngle - halfAngleOfAreaInView),
    ),
    computeDestinationPointLonLat(
      { lon: cameraLng, lat: cameraLat },
      distance,
      roundAngle(bearingAngle + halfAngleOfAreaInView),
    ),
    { lat: cameraLat, lon: cameraLng },
  ].map(lngLatToArray);
}

/**
 * Gets the perimeter points of a polygon that approximates a circle to represent the viewshed
 * @note A polygon is used rather than a circle becuase mapbox
 * circle's pixel radius does not scale with zoom
 */
export const getStationViewshedPerimeterPoints = (
  /** The lon/lat of the station */
  stationLonLat: LonLat,
  /** the radius of the viewshed */
  viewshedRadius: number,
) => {
  const { lat, lon } = stationLonLat;
  const area = [];
  for (let angle: number = 0; angle < 360; angle += 1) {
    const point = computeDestinationPointLonLat({ lon, lat }, viewshedRadius, angle);
    area.push([point.lon, point.lat]);
  }
  const _point: LonLat = computeDestinationPointLonLat({ lon, lat }, viewshedRadius, 0);
  area.push([_point.lon, _point.lat]);

  return area;
};

export function getGDACoordinatesFromLonLat(lonLat: LonLat): GDACoordinates {
  const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
  const utmZone = Math.floor((lonLat.lon + 180) / 6) + 1;
  const gda2020Projection = `+proj=utm +zone=${utmZone} +south +datum=GDA2020 +units=m +no_defs`;

  const gda2020Coordinates = proj4(wgs84Projection, gda2020Projection, [lonLat.lon, lonLat.lat]);

  const roundedGDA2020Coordinates: GDACoordinates = {
    easting: Math.round(gda2020Coordinates[0]),
    northing: Math.round(gda2020Coordinates[1]),
    zone: utmZone,
  };

  return roundedGDA2020Coordinates;
}
