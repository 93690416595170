/**
 * An abstract "API proxy" that facilitates the selection and insertion of data that is sorted by a dimension that can be denoted as
 * a "range" (such as time or distance). A developer can chain separate implementations of {@link RangeFilter}, allowing
 * higher-level patterns to manage selected or inserted data in a memory cache (fastest), vs database calls (slower), vs API calls
 * (slowest). When data is fetched (via the {@link #fetch(Comparable,Comparable)} method), the {@link RangeFilter} attempts to
 * retrieve the requested range from the "top" tier of the stack. If all or a part of the range is not present in the tier, the
 * {@link RangeFilter} attempts to fetch the missing portion of the range from the next tier. Upon successfully fetching the data,
 * the {@link RangeFilter} thereafter inserts the fetched data into the top tier, thus resembling a caching mechanism. The
 * {@link RangeFilter} is intended to provide an abstraction for the caching of range data belonging to a data source that is
 * expensive to call, such as a remote database.
 *
 * @param <A> Type parameter of the "range" data.
 * @param <B> Type parameter of the data.
 */
export default abstract class RangeFilter<T> {
  private next: RangeFilter<T>;

  constructor(next: RangeFilter<T>) {
    this.next = next;
  }

  /**
   * Returns a {@link Map} of data from {@code from} (inclusive) to {@code to} (exclusive).
   *
   * @param from The lower bound of the range, inclusive.
   * @param to The upper bound of the range, exclusive.
   * @return A {@link Map} of data from {@code from} (inclusive) to {@code to} (exclusive).
   */
  public async fetch(from: number, to: number): Promise<Map<number, T>> {
    return this.fetchNext(from, to, null);
  }

  /**
   * Returns a {@link Map} of data from {@code from} (inclusive) to {@code to} (exclusive).
   *
   * @param from The lower bound of the range, inclusive.
   * @param to The upper bound of the range, exclusive.
   * @param prev The {@link RangeFilter} representing the previous tier.
   * @return A {@link Map} of data from {@code from} (inclusive) to {@code to} (exclusive).
   */
  public async fetchNext(from: number, to: number, prev: RangeFilter<T>): Promise<Map<number, T>> {
    const range: number[] = this.range();
    let r0: number;
    let r1: number;
    if (range == null || (r0 = range[0]) == (r1 = range[1])) {
      if (this.next == null) return null;

      const data: Map<number, T> = await this.next.fetchNext(from, to, prev);
      this.insert(from, to, data);

      return data;
    }

    if (this != prev) {
      if (to <= r0) {
        console.debug(this + '{1} (' + from + ', ' + r0 + ']');
        this.insert(from, r0, await this.next.fetchNext(from, r0, prev));
      } else if (r1 <= from) {
        console.debug(this + ' {2} (' + r1 + ', ' + to + ']');
        this.insert(r1, to, await this.next.fetchNext(r1, to, prev));
      } else {
        if (from < r0) {
          console.debug(this + ' {3} (' + from + ', ' + r0 + ']');
          this.insert(from, r0, await this.next.fetchNext(from, r0, prev));
        }

        if (r1 < to) {
          console.debug(this + ' {3} (' + r1 + ', ' + to + ']');
          this.insert(r1, to, await this.next.fetchNext(r1, to, prev));
        }
      }
    }

    return this.select(from, to);
  }

  /**
   * Returns the range of the keys present in this {@link RangeFilter}, as an array of length 2. Must not be null, and must
   * be of length 2.
   *
   * @return The not-null range of the keys present in this {@link RangeFilter}, as an array of length 2.
   */
  protected abstract range(): number[];

  /**
   * Returns a {@link Map} of data in this {@link RangeFilter} for the range between {@code from} and {@code to}.
   *
   * @param from The start of the range, inclusive.
   * @param to The end of the range, exclusive.
   * @return A {@link Map} of data in this {@link RangeFilter} for the range between {@code from} and {@code to}.
   */
  // eslint-disable-next-line no-unused-vars
  protected abstract select(from: number, to: number): Promise<Map<number, T>>;

  /**
   * Inserts a {@link Map} of {@code data} into this {@link RangeFilter} for the range between {@code from} and
   * {@code to}.
   *
   * @param from The start of the range, inclusive.
   * @param to The end of the range, exclusive.
   * @param data The {@link Map}.
   */
  // eslint-disable-next-line no-unused-vars
  protected abstract insert(from: number, to: number, data: Map<number, T>): void;
}
