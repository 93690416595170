import React, { useEffect } from 'react';
import { setupPublicApi } from 'config/api';
import { rscGlobalAlert } from 'data';
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { GlobalAlertParams } from 'types';

import useInterval from '@use-it/interval';

const Heartbeat: React.FC = () => {
  const setGlobalAlert: SetterOrUpdater<GlobalAlertParams> = useSetRecoilState(rscGlobalAlert);

  // Check local account info every 5 seconds
  useInterval(async () => {
    // await authStore.syncAccount();
  }, 5000);

  useEffect(() => {
    setupPublicApi(setGlobalAlert);
  }, [setGlobalAlert]);

  return null;
};

export default React.memo(Heartbeat);
