import { INCIDENT_LABEL, IncidentLabel, MapAssetDataSet, Ptz } from 'types';

export const INPUT_DEBOUNCE_TIMEOUT = 350;
/** The max number of incidents to return from the incident API */
export const INCIDENT_LIMIT = 1000;

export const API_TRACE_HEADER: string = 'correlation-id';
/** The header to indicate MFA status */
export const API_HEADER_MFA: string = 'x-pano-mfa';
export const API_TIMEOUT: number = 30000; // Timeout 30 seconds for all API calls.
/** @todo If cache size goes beyond 1000, show warning now. */
export const API_CACHE_SIZE: number = 1000;
export const LOOP_INTERVAL_60S: number = 60_000; // 60 seconds loop interval
export const LOOP_INTERVAL_5M: number = 300_000; // 5 minutes loop interval
export const OZ_SESSION_LIVE_MAX_MS = Number(localStorage?.getItem('OZ_SESSION_LIVE_MAX_MS')) || 10 * 60 * 1000; // An OZ session lasts only for max 10 minutes
/** Seconds until a station is considered offline if no images are recieved, currently 10 minutes */
export const DEFAULT_PLAYER_PERIOD: number = 10 * 60;
/** Earliest possible date for an incident
 * @jira https://panoai.atlassian.net/browse/RD-504 */
export const MIN_INCIDENT_DATE: Date = new Date(1000000000000); // 09/09/2001
/** Lowest possiple incident id */
export const MIN_INCIDENT_ID: number = 1;
export const USER_FIELD: string = 'PANO_USER';
export const EMPTY_FUNCTION = (): void => {};
/** the distance between the start and end of a gradient section of the incident bar */
export const INCIDENT_BAR_GRADIENT_METERS = 250;
/** the length of an uncalibrated incident bar */
export const INCIDENT_BAR_UNCALIBRATED_LENGTH = 20000;
/** factor to scale the width of the incident to the border and chevrons, 6 = 1/6  */
export const INCIDENT_BAR_BORDER_WIDTH_SCALE = 6;
export const METERS_IN_KM = 1000;
/** the width of the incident bar that a dot's width will be, 0.1 is 10% */
export const INCIDENT_BAR_DOT_WIDTH_SCALE = 0.1;

export const INCIDENT_FILTER_QUERY_FIELD: string = 'incidentFilterQuery';
export const INCIDENT_OCV_FILTER_QUERY_FIELD: string = 'incidentOCVFilterQuery';
export const INITIAL_FILTERS: IncidentLabel[] = [
  INCIDENT_LABEL.POSSIBLE,
  INCIDENT_LABEL.CLOSED,
  INCIDENT_LABEL.DISMISSED,
  INCIDENT_LABEL.PRESCRIBED,
  INCIDENT_LABEL.CONFIRMED,
];
export const INCIDENT_ORDERS: { [x: string]: number } = {
  [INCIDENT_LABEL.DISMISSED]: 0,
  [INCIDENT_LABEL.CLOSED]: 1,
  [INCIDENT_LABEL.PRESCRIBED]: 2,
  [INCIDENT_LABEL.CONFIRMED]: 3,
  [INCIDENT_LABEL.POSSIBLE]: 4,
};

export const DEFAULT_PAN_TILT_ZOOM: Ptz = {
  pan: 0,
  tilt: 0,
  zoom: 1,
};

export const PGE_ASSET_DATASETS: MapAssetDataSet[] = [
  MapAssetDataSet.PgeOverheadMainlines,
  MapAssetDataSet.PgeOverheadTaplines,
  MapAssetDataSet.PgeUndergroundMainlines,
  MapAssetDataSet.PgeUndergroundTaplines,
  MapAssetDataSet.PgeLocation,
  MapAssetDataSet.PgeSubstation,
  MapAssetDataSet.PgeHydroPlant,
];
