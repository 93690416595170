import { useCallback } from 'react';
import { rsaHasBearerToken, rspUpdateOzStationsByEcs, rssUserStations, rssUserStationsTs } from 'data';
import { StationApis } from 'data/proxyApi';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Station } from 'types';
import { applyArrayDifference, isExpired, nowMs } from 'utils';

interface Returned {
  refreshStations: (_forceFetch?: boolean, _incremental?: boolean) => Promise<Station[]>;
}

// Not using useState in case `refreshStations` be called quickly within really short time.
let gettingUserStations: boolean = false;
const setGettingUserStations = (getting: boolean): void => {
  gettingUserStations = getting;
};

export default function useStations(): Returned {
  const [userStations, setUserStations] = useRecoilState(rssUserStations);
  const hasBearerToken: boolean = useRecoilValue(rsaHasBearerToken);
  const [userStationsTs, setUserStationsTs] = useRecoilState(rssUserStationsTs);
  const updateOzStationsByEcs: SetterOrUpdater<Station[]> = useSetRecoilState(rspUpdateOzStationsByEcs);

  const refreshStations: (_forceFetch: boolean, _incremental: boolean) => Promise<Station[]> = useCallback(
    async (forceFetch = false, incremental = false) => {
      // Get stations either from Recoil store or from API.
      if (!hasBearerToken) {
        return [];
      }

      // Another call happens just now, ignore this call.
      if (gettingUserStations) {
        return userStations;
      }

      if (incremental) {
        setGettingUserStations(true);
        const diffStations: Station[] = await StationApis.apiGetStations(userStationsTs, true);
        if (diffStations && diffStations.length > 0) {
          updateOzStationsByEcs(diffStations);
        }
        setGettingUserStations(false);
        if (diffStations.length > 0) {
          const stations: Station[] = applyArrayDifference<Station>(userStations, diffStations);
          setUserStations(stations);
          setUserStationsTs(nowMs());

          return stations;
        }

        return userStations;
      }

      if (!forceFetch && !isExpired(userStationsTs)) {
        return userStations;
      }

      setGettingUserStations(true);
      const stations: Station[] = await StationApis.apiGetStations();
      if (stations && stations.length > 0) {
        updateOzStationsByEcs(stations);
      }
      setGettingUserStations(false);
      setUserStations(stations);
      setUserStationsTs(nowMs());

      return stations;
    },
    [hasBearerToken, setUserStations, setUserStationsTs, userStations, userStationsTs, updateOzStationsByEcs],
  );

  return { refreshStations };
}
