import { INCIDENT_BAR_DOT_WIDTH_SCALE, INCIDENT_BAR_UNCALIBRATED_LENGTH } from 'config/constants';
import dayjs from 'dayjs';
import _compact from 'lodash/compact';
import _every from 'lodash/every';
import _flatten from 'lodash/flatten';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _head from 'lodash/head';
import _mapValues from 'lodash/mapValues';
import _round from 'lodash/round';
import _trim from 'lodash/trim';
import { LayerProps } from 'react-map-gl';
import {
  GDACoordinates,
  Incident,
  INCIDENT_LABEL,
  IncidentColors,
  IncidentLabel,
  LonLat,
  LonLatArray,
  MapMode,
  MapSourceData,
  Station,
  StationsMap,
} from 'types';
import {
  CAMERA_IN_VIEW_CONE_ANGLE,
  CAMERA_UNCERTAINTY_ANGLE,
  computeDestinationPointLonLat,
  getCameraUncertaintyAngleByMapZoom,
  getChevronPoints,
  getIncidentBarWidth,
  getIncidentLabel,
  getPolygonPointsLatLng,
  getRectanglePoints,
  getRectPointsLatLng,
  getTrianglePoints,
  getTwoDigitHexString,
  hasLngLat,
  hasNoLngLat,
} from 'utils';

const MAX_LNGLAT_SCALE: number = 7;

const lightMode: string = 'mapbox://styles/mapbox/light-v9';
const darkMode: string = 'mapbox://styles/mapbox/dark-v9';
const outdoorsMode: string = 'mapbox://styles/mapbox/outdoors-v11';
const satelliteStreetsMode: string = 'mapbox://styles/mapbox/satellite-streets-v11';

export const mapModes: Record<MapMode, string> = {
  light: lightMode,
  dark: darkMode,
  map: outdoorsMode,
  satellite: satelliteStreetsMode,
};

export const mapList: { name: MapMode; img: string }[] = [
  { name: 'map', img: 'outdoors-v11' },
  { name: 'satellite', img: 'satellite-streets-v11' },
];

export const incidentResolutionTitle: Record<IncidentLabel, string> = {
  possible: 'Smoke Investigation',
  confirmed: 'Alerted Incident',
  prescribed: 'Controlled Burn',
  dismissed: 'Dismissed Investigation',
  closed: 'Closed Incident',
};

export const getIncidentLabelTime = (label: IncidentLabel, readableTime = ''): string =>
  ({
    [INCIDENT_LABEL.POSSIBLE]: `Detected ${readableTime}`,
    [INCIDENT_LABEL.PRESCRIBED]: `Active for ${readableTime}`,
    [INCIDENT_LABEL.CONFIRMED]: `Active for ${readableTime}`,
    [INCIDENT_LABEL.DISMISSED]: `Dismissed ${readableTime}`,
    [INCIDENT_LABEL.CLOSED]: `Closed for ${readableTime}`,
  }[label] || readableTime);

export const incidentSourceDescPopup: Record<string, string> = {
  dispatch: '911',
  cv: 'PanoCam',
  burn: 'Active',
  satellite: 'Satellite',
  closed: 'Closed',
  user: 'User',
};

//function to calculate the time period incident start time and now
export function getIncidentHappenedDuration(startTime: number): string {
  let durationSymbol: string = 'm';
  const endTime: dayjs.Dayjs = dayjs();
  const seconds: number = endTime.diff(startTime * 1000, 'seconds');
  let duration: number = seconds / 60;
  if (duration < 1) duration = 1;
  if (duration > 60) {
    duration = duration / 60;
    durationSymbol = 'h';
    if (duration > 24) {
      duration = duration / 24;
      durationSymbol = 'd';
    }
  }

  return Math.floor(duration) + durationSymbol;
}

//fill color for camera area circle
export const stationDataLayer: LayerProps = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'opacity',
      stops: [
        [0, 'rgba(53, 131, 188, 0.15)'],
        [1, 'rgba(53, 131, 188, 0.15)'],
      ],
    },
    'fill-opacity': 1,
  },
};

/**
 * Returns a map layer styling object that uses a feature's color
 * and opacity properties for styling
 */
export const getPolygonLayerDynamicStyling = (id: string) => ({
  id: `${id}`,
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': ['get', 'opacity'],
    'fill-outline-color': 'transparent',
  },
});

/**
 * Get camera circle polygon coordinates
 * These are used to create the map layer
 */
export function getStationViewshedFeatureData(perimeterPoints: number[][]): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: { opacity: 0 },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[perimeterPoints]],
        },
      },
    ],
  };
}

const INCIDENT_COLORS: IncidentColors = {
  POSSIBLE: '6, 118, 188',
  PRESCRIBED: '108, 115, 132',
  CONFIRMED: '226, 3, 9',
  CLOSED: '108, 115, 132',
  DISMISSED: '108, 115, 132',
};

/**
 * Styling for the incidents map layers
 */
export const getIncidentDataLayer = (
  /** the incident label, to determine its color */
  label: IncidentLabel,
): LayerProps => {
  const rgb = INCIDENT_COLORS[label.toUpperCase() as keyof typeof INCIDENT_COLORS] || '226, 3, 9';
  const id = label ? `${label.toLowerCase()}-data` : 'data';

  return {
    id,
    type: 'fill',
    paint: {
      'fill-color': {
        property: 'opacity',
        stops: [
          [0.1, `rgba(${rgb}, 0.1)`],
          [0.5, `rgba(${rgb}, 0.5)`],
          [1, `rgba(${rgb}, 1)`],
        ],
      },
      'fill-outline-color': 'transparent',
      'fill-opacity': 1,
    },
  };
};

/**
 * The styling associated with incident and camera bearing cones
 */
export const getIncidentConeStyling = (
  /** the incident label, to determine its color */
  label: IncidentLabel,
): LayerProps => {
  const rgb = INCIDENT_COLORS[label.toUpperCase() as keyof typeof INCIDENT_COLORS] || '226, 3, 9';
  const id = 'data';

  return {
    id,
    type: 'fill',
    paint: {
      'fill-color': {
        property: 'opacity',
        stops: [
          [0.1, `rgba(${rgb}, 0.1)`],
          [0.5, `rgba(${rgb}, 0.5)`],
          [1, `rgba(${rgb}, 1)`],
        ],
      },
      'fill-outline-color': 'transparent',
      'fill-opacity': 1,
    },
  };
};

export const getAllIncidentsDataLayer = (): Record<string, LayerProps> => {
  const labeledLayers: Record<string, LayerProps> = {};
  Object.keys(INCIDENT_COLORS).forEach((definedLabel) => {
    labeledLayers[definedLabel.toLowerCase()] = getIncidentDataLayer(definedLabel as IncidentLabel);
  });

  return labeledLayers;
};

export const labeledIncidentLayers: Record<string, LayerProps> = getAllIncidentsDataLayer();

export const basePolygon = (points: LonLatArray[], opacity = 1): GeoJSON.Feature<GeoJSON.MultiPolygon> => ({
  type: 'Feature',
  properties: { opacity },
  geometry: {
    type: 'MultiPolygon',
    coordinates: [[points]],
  },
});

/**
 * A GeoJSON polygon feature from a set of points
 * @param points polygon verticies
 * @param color polygon fill color
 * @param opacity fill color opacity
 */
export const coloredPolygonFeature = (points: LonLatArray[], color: string, opacity = 1): GeoJSON.Feature => ({
  type: 'Feature',
  properties: { color, opacity },
  geometry: {
    type: 'MultiPolygon',
    coordinates: [[points]],
  },
});

export function getStationViewPolygon(
  station: Station,
  bearing: number = null,
  angle: number = CAMERA_IN_VIEW_CONE_ANGLE,
): MapSourceData {
  const polygonPoints = getPolygonPointsLatLng({
    incidentCamera: null,
    station,
    coneAngle: angle,
    canvasBearing: bearing,
  });
  const cone = basePolygon(polygonPoints, 0.3);

  return {
    type: 'FeatureCollection',
    features: [cone],
  };
}

interface RectangleFeatureProps {
  /** centroid latitude */
  lat: number;
  /** centroid longitude */
  lon: number;
  /** distance in meters from centroid to start of rectangle */
  start: number;
  /** distance in meters from centroid to end of rectangle */
  end: number;
  /** color of the feature */
  color: string;
  /** opacity of the feature */
  opacity: number;
  /** map zoom level */
  zoom: number;
  /** bearing of the rectangle midline from the centroid */
  bearing: number;
}

/**
 * Returns a GeoJSON rectanlge polygon feature
 */
export const getRectangleFeature = ({ lat, lon, start, end, color, zoom, opacity, bearing }: RectangleFeatureProps) => {
  const width = getIncidentBarWidth(zoom);
  const rectanglePoints = getRectanglePoints({ lat, lon, start, end, bearing, width });

  return coloredPolygonFeature(rectanglePoints, color, opacity);
};

/**
 * The GEOJson features that make up the dotted line between the end of an incident's
 * viewshed and the beginning of the incidents predicted range
 */
export const getDottedLineFeatures = ({
  lat,
  lon,
  start,
  end,
  color,
  opacity,
  zoom,
  bearing,
}: RectangleFeatureProps) => {
  const features = [];
  const width = getIncidentBarWidth(zoom) * INCIDENT_BAR_DOT_WIDTH_SCALE;
  const incidentBarDotLength = getIncidentBarWidth(zoom);
  for (let dotStart = start; dotStart < end; dotStart += 2 * incidentBarDotLength) {
    const dotEnd = Math.min(dotStart + incidentBarDotLength, end);
    const rectanglePoints = getRectanglePoints({
      lat,
      lon,
      start: dotStart,
      end: dotEnd,
      bearing,
      width,
    });
    features.push(coloredPolygonFeature(rectanglePoints, color, opacity));
  }

  return features;
};

interface GradientRectangleFeatureProps {
  lat: number;
  lon: number;
  start: number;
  end: number;
  startColor: string;
  endColor: string;
  startOpacity: number;
  endOpacity: number;
  zoom: number;
  bearing: number;
}

/** Returns the number of gradient intervals based on zoom level
 * @note At lower zooms (more zoomed out) lower step counts prevents feature dropping
 */
const getStepCountFromZoomLevel = (zoom: number) => {
  if (zoom > 12) {
    return zoom * 2;
  } else if (zoom > 11) {
    return 5;
  }

  return 2;
};

/**
 * Returns the rectangle GeoJSON features that make up a gradient between
 * start and end distances
 */
export const getGradientRectangleFeatures = ({
  lat,
  lon,
  start,
  end,
  startColor,
  endColor,
  startOpacity,
  endOpacity,
  zoom,
  bearing,
}: GradientRectangleFeatureProps) => {
  const features = [];

  const gradientSteps = getStepCountFromZoomLevel(zoom);
  const width = getIncidentBarWidth(zoom);

  for (let i = 0; i < gradientSteps; i++) {
    const stepColor = getMixedColor(startColor, endColor, i, gradientSteps);
    const stepOpacity = getMixedOpacity(startOpacity, endOpacity, i, gradientSteps);
    const stepStart = start + i * ((end - start) / gradientSteps);
    const stepEnd = start + (i + 1) * ((end - start) / gradientSteps);

    const rectanglePoints = getRectanglePoints({ lat, lon, start: stepStart, end: stepEnd, width, bearing });
    const feature = coloredPolygonFeature(rectanglePoints, stepColor, stepOpacity);
    features.push(feature);
  }

  return features;
};

interface TriangleFeatureProps {
  /** centroid latitude */
  lat: number;
  /** centroid longitude */
  lon: number;
  /** distance in meters from centroid to base of triangle */
  start: number;
  /** the color of the triangle */
  polygonColor: string;
  /** opacity of the triangle */
  opacity: number;
  /** the color of the chevrons */
  chevronColor: string;
  /** map zoom level */
  zoom: number;
  /** bearing of the rectangle midline from the centroid */
  bearing: number;
}

/**
 * Returns the GeoJSON features of a triangle and two chevrons
 * that represent an incident with an unknown end of range
 */
export const getPointedTipFeatures = ({
  lat,
  lon,
  start,
  polygonColor,
  zoom,
  opacity,
  chevronColor,
  bearing,
}: TriangleFeatureProps) => {
  const width = getIncidentBarWidth(zoom);

  const trianglePoints = getTrianglePoints({ lat, lon, start, width, bearing });
  const triangleFeature = coloredPolygonFeature(trianglePoints, polygonColor, opacity);

  const chevron1Points = getChevronPoints({ lat, lon, start: start + width, bearing, width });
  const chevron1Feature = coloredPolygonFeature(chevron1Points, chevronColor, opacity);

  const chevron2Points = getChevronPoints({ lat, lon, start: start + 2 * width, width, bearing });
  const chevron2Feature = coloredPolygonFeature(chevron2Points, chevronColor, opacity);

  return [triangleFeature, chevron1Feature, chevron2Feature];
};

interface ChevronFeatureProps {
  /** centroid latitude */
  lat: number;
  /** centroid longitude */
  lon: number;
  /** the color of the chevron */
  color: string;
  /** opacity of the feature */
  opacity: number;
  /** map zoom level */
  zoom: number;
  /** bearing of the rectangle midline from the centroid */
  bearing: number;
}

/**
 * Returns the GeoJSON chevron features that represent an uncalibrated incident
 */
export const getStackedChevronFeatures = ({ lat, lon, color, zoom, opacity, bearing }: ChevronFeatureProps) => {
  const features = [];
  const width = getIncidentBarWidth(zoom);

  for (let chevronStart = 0; chevronStart < INCIDENT_BAR_UNCALIBRATED_LENGTH; chevronStart += width) {
    const chevronPoints = getChevronPoints({ lat, lon, start: chevronStart, width, bearing });
    const chevronFeature = coloredPolygonFeature(chevronPoints, color, opacity);
    features.push(chevronFeature);
  }

  return features;
};

/**
 * Returns an opacity from the current step, out of a total number of steps that equally mixes the
 * start and end opacities between the total number of steps
 */
export const getMixedOpacity = (startOpacity: number, endOpacity: number, currentStep: number, totalSteps: number) => {
  return ((totalSteps - currentStep) * startOpacity) / totalSteps + (currentStep * endOpacity) / totalSteps;
};

/**
 * Returns a color from the current step, out of a total number of steps that equally mixes the
 * start and end colors between the total number of steps
 * @note this trivial implementation ignores color theory and mixing relative color lightness
 * and would preform poorly under more complex use cases
 */
export const getMixedColor = (startColor: string, endColor: string, currentStep: number, totalSteps: number) => {
  const startColorR = startColor.substring(1, 3);
  const startColorG = startColor.substring(3, 5);
  const startColorB = startColor.substring(5, 7);
  const endColorR = endColor.substring(1, 3);
  const endColorG = endColor.substring(3, 5);
  const endColorB = endColor.substring(5, 7);

  const decimalRed =
    ((totalSteps - currentStep) * parseInt(startColorR, 16)) / totalSteps +
    (currentStep * parseInt(endColorR, 16)) / totalSteps;
  const hexRed = getTwoDigitHexString(decimalRed);

  const decimalGreen =
    ((totalSteps - currentStep) * parseInt(startColorG, 16)) / totalSteps +
    (currentStep * parseInt(endColorG, 16)) / totalSteps;
  const hexGreen = getTwoDigitHexString(decimalGreen);

  const decimalBlue =
    ((totalSteps - currentStep) * parseInt(startColorB, 16)) / totalSteps +
    (currentStep * parseInt(endColorB, 16)) / totalSteps;
  const hexBlue = getTwoDigitHexString(decimalBlue);

  return `#${hexRed}${hexGreen}${hexBlue}`;
};

export function getIncidentCameraCones(incident: Incident, mapZoom: number, stationsMap: StationsMap): MapSourceData {
  const features = incident.cameras
    .filter((c) => !!stationsMap[c?.id])
    .map((incidentCamera) => {
      const station = stationsMap[incidentCamera?.id];
      const areaPolygonPoints = getPolygonPointsLatLng({ incidentCamera, station });
      const shapes = [basePolygon(areaPolygonPoints, 0.3)];

      if (hasLngLat(station)) {
        const rectPoints = getRectPointsLatLng(incident, station, incidentCamera, mapZoom);
        const rect = basePolygon(rectPoints, 0.3);
        shapes.push(rect);

        const uncertaintyPolygonPoints = getPolygonPointsLatLng({
          incidentCamera,
          station,
          coneAngle: CAMERA_UNCERTAINTY_ANGLE,
        });
        const uncertaintyCone = basePolygon(uncertaintyPolygonPoints, 0.5);
        shapes.push(uncertaintyCone);
      }

      return shapes;
    });

  return {
    type: 'FeatureCollection',
    features: _flatten(features),
  };
}

// TODO: Remove this function after completion of
// https://panoai.atlassian.net/browse/RD-5411 + https://panoai.atlassian.net/browse/RD-5409
export function getIncidentBarFeatures(
  incidents: Incident[],
  /** the id of the selected incident, used for styling */
  highlightedIncidentId: number,
  stationsMap: StationsMap,
  /** map zoom level, used to determine the width of the feature */
  mapZoom: number,
): Record<string, MapSourceData> {
  const groupedData: Record<string, MapSourceData> = {};

  const incidentsGroupedByLabel = _groupBy(incidents, getIncidentLabel);

  _forEach(incidentsGroupedByLabel, (labeledIncidents, label) => {
    const features = labeledIncidents.map((incident) => {
      // If no currentIncident, highlight all; otherwise, highlight the currentIncident
      const highlight: boolean = !highlightedIncidentId || incident.id === highlightedIncidentId;

      return incident.cameras
        .filter((c) => c.mark)
        .filter((c) => !!stationsMap[c?.id])
        .map((incidentCamera) => {
          const station = stationsMap[incidentCamera?.id];
          const rectPoints = getRectPointsLatLng(incident, station, incidentCamera, mapZoom);
          const rect = basePolygon(rectPoints, highlight ? 0.3 : 0.25);

          if (!highlight) {
            return [rect];
          }

          const uncertaintyPolygonPoints = getPolygonPointsLatLng({
            incidentCamera,
            station,
            coneAngle: getCameraUncertaintyAngleByMapZoom(mapZoom),
          });
          const uncertaintyCone = basePolygon(uncertaintyPolygonPoints, highlight ? 1 : 0.5);

          return [rect, uncertaintyCone];
        });
    });

    groupedData[label.toLowerCase()] = {
      type: 'FeatureCollection',
      features: _flatten(_flatten(features)),
    };
  });

  return groupedData;
}

export function getIncidentUncertainFireLonLat(incident: Incident, stations: Station[]): LonLat {
  const incidentCamera = _head(incident?.cameras);
  const station = stations.find((i) => i?.id === incidentCamera?.id);
  if (!incidentCamera || !station?.lon || !station?.lat) {
    // console.warn('No uncertain fire', incident);
    return null;
  }
  const { bearing } = incidentCamera;
  // @todo always use the visibility from the first camera of a station.
  const visibility = ((_get(incidentCamera, 'cameras.0.visibility') as number) || 20) * 1000;

  const lonLat = computeDestinationPointLonLat({ lon: station.lon, lat: station.lat }, visibility / 2, bearing);

  if (!lonLat.lon || !lonLat.lat) {
    console.warn('cannot determine the longitude/latitude of incident', incident);

    return null;
  }

  return lonLat;
}

export const stringifyGDACoordinates = (gda: GDACoordinates) => {
  return `E:\u00A0${gda.easting}, N:\u00A0${gda.northing}, Z:\u00A0${gda.zone}`;
};

export const stringifyLonLat = (lonLat: LonLat): string => {
  return `${lonLat.lat.toFixed(5)}, ${lonLat.lon.toFixed(5)}`;
};

export const lngLatForApi = (lonLat: LonLat): LonLat => {
  return _mapValues(lonLat, (v) => _round(v, MAX_LNGLAT_SCALE));
};

export const isLonLat = (str: string): boolean => {
  const lonLat = _compact(str.split(/[, ]/));
  const isLonLatRange = (n: number): boolean => n >= -180 && n <= 180;

  return lonLat.length === 2 && _every(lonLat.map(Number), isLonLatRange);
};

export const reverseLonLat = (str: string): string => _compact(str.split(/[, ]/)).map(_trim).reverse().join(', ');

// Convert distance of two points from coordinate values to millimeter (approximate)
export const getMilliMeterDistanceFromLonLat = (loc1: LonLat, loc2: LonLat, zoom: number): number => {
  const dis = Math.pow(loc2.lat - loc1.lat, 2) + Math.pow(loc2.lon - loc1.lon, 2);

  return Math.sqrt(dis) * Math.pow(2, zoom - 1);
};

// The diameter of incident/station icon is 10mm.
// If center point of two incidents has distance less than MAX_OVERLAPPING_IN_MM (mm), we think they overlap, then do not plot overlapping ones.
const MAX_OVERLAPPING_IN_MM: number = 5;
export const keepNonOverlappingPoints = (points: Incident[], zoom: number): Incident[][] => {
  const nonOverlapping: Incident[][] = [];
  for (let i: number = 0; i < points.length; i++) {
    const point: Incident = points[i];
    if (hasNoLngLat(point)) {
      continue;
    }

    if (nonOverlapping.length === 0) {
      nonOverlapping.push([point]);
    } else {
      let overlapped: boolean = false;
      for (let j: number = 0; j < nonOverlapping.length; j++) {
        const pointsIn: Incident[] = nonOverlapping[j];
        const dis: number = getMilliMeterDistanceFromLonLat(point as LonLat, _head(pointsIn) as LonLat, zoom);
        if (dis <= MAX_OVERLAPPING_IN_MM) {
          overlapped = true;
          pointsIn.push(point);
          break;
        }
      }

      if (!overlapped) {
        nonOverlapping.push([point]);
      }
    }
  }

  return nonOverlapping;
};
