import { IS_PIC_USER_AVAILABLE, IS_PIC_WORKFLOW_REQUESTED } from 'config/base';
import { localStorageEffect, localStorageEffectLiveSync } from 'data/effect';
import { atom, selector } from 'recoil';
import { PICWorkflowState } from 'types';

import { rsaOrgIsPanoType } from '../authStore';
import { rstTask } from '../taskStore';

/**
 * Whether the user has requested to see the pic workflow
 * - Determined by `?pictask=1`
 */
export const rspwIsPicWorkflowRequested = atom({
  key: 'rspwIsPicWorkflowRequested',
  default: false,
  effects: [localStorageEffect(IS_PIC_WORKFLOW_REQUESTED, null)],
});

/**
 * Whether or not the user is currently ready to accept new tasks
 */
export const rspwIsPicUserAvailableSynced = atom({
  key: 'rspwIsPicUserAvailableSynced',
  default: false,
  effects: [localStorageEffectLiveSync(IS_PIC_USER_AVAILABLE, null)],
});

/**
 * Returns true if a user should be able to see the PIC Workflow Sidebar
 * - user is pano
 * - user requested the pic workflow
 */
export const rspwIsUserPICWorkflow = selector({
  key: 'rspwIsUserPICWorkflow',
  get: ({ get }) => {
    const isOrgPano = get(rsaOrgIsPanoType);
    const isPicWorkflowRequested = get(rspwIsPicWorkflowRequested);

    return !!(isOrgPano && isPicWorkflowRequested);
  },
});

/**
 * Returns which state of the PIC Workflow a user is in
 * - Inactive - pic user unavailable
 * - Searching - User is available and has no task
 * - Active - User is available and has a task
 */
export const rspwPICWorkflowState = selector({
  key: 'rspwPICWorkflowState',
  get: ({ get }) => {
    const task = get(rstTask);
    const isPicUserAvailable = get(rspwIsPicUserAvailableSynced);

    if (!isPicUserAvailable) {
      return PICWorkflowState.inactive;
    } else if (isPicUserAvailable && !task) {
      return PICWorkflowState.searching;
    }

    return PICWorkflowState.active;
  },
});

/**
 * Whether or not the Save Reminder should show when a user tries to close the page
 */
export const rspwIsSaveReminderActive = selector({
  key: 'rspwIsSaveReminderActive',
  get: ({ get }) => {
    const picWorkflowState = get(rspwPICWorkflowState);

    return picWorkflowState !== PICWorkflowState.inactive;
  },
});
