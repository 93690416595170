import _every from 'lodash/every';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _sumBy from 'lodash/sumBy';
import { PanoData, Station, StationCamera } from 'types';
import { StationOwnerType } from 'types/enums';

/**
 * @returns {number} The initial angle of the panorama in degrees, based on the startAngle of camera0.
 * - E.G. If this is 45. Then when you open the pano the left bound of the Bearing scale will read 45.
 */
export const getStartAngle = (station: Station): number => station?.cameras?.[0]?.fov?.[0] || 0;

export const getCameraFovPan = (camera: StationCamera): number =>
  Math.abs(_get(camera, 'fov.1', 0) - _get(camera, 'fov.0', 0));
export const getCameraDois = (camera: StationCamera): number => _get(camera, 'dois', 0);

// Sum all cameras `fovPan`
export const getStationFovPan = (station: Station): number =>
  Math.round(_sumBy(_get(station, 'cameras', []), getCameraFovPan) || 360);

// Sum all cameras `dois`
export const getStationDois = (station: Station): number =>
  Math.round(_sumBy(_get(station, 'cameras', []), getCameraDois));

export const getStationImageDimension = (station: Station): [number, number] => {
  const { panoWidth, panoHeight, fovPan, dois } = station;
  // 180 degree cameras have half dois of 360 degree cameras.
  const ratio: number = fovPan === 360 ? 1 : 2;

  return [panoWidth / dois / ratio, panoHeight];
};

export const get180Dois = (station: Station): number => {
  const { dois, fovPan }: Station = station;
  const ratio: number = fovPan === 180 ? 1 : 2;

  return dois / ratio;
};

export const getThumbnailImagesCount: (station: Station) => number = get180Dois;

export const hitStation = (station: Station, query: string): boolean => {
  const stationStr: string = [
    station.id,
    station.name,
    station.place?.state,
    station.place?.city,
    station.place?.county,
  ]
    .filter((f) => f || '')
    .join(' ')
    .toLowerCase();

  return stationStr.indexOf(query.toLowerCase()) >= 0;
};

export const getStationText = (station: Station): string => (station?.op === 'a' ? 'station' : 'camera');

export const getStationTextByOwnerType = (stationsOwnerType: string, plural = true, lowercase = false): string => {
  // const stationsOwnerType = getStationsOwnerType(stations)
  let label: string = plural ? 'Stations' : 'Station';
  if (stationsOwnerType === StationOwnerType.ThirdParty) {
    label = plural ? 'Cameras' : 'Camera';
  } else if (stationsOwnerType === StationOwnerType.Mixed) {
    label = plural ? 'Stations & Cameras' : 'Station & Camera';
  }

  return lowercase ? label.toLowerCase() : label;
};

export const getSearchStationText = (type: string): string => {
  if (type === StationOwnerType.Mixed) {
    return 'station or camera';
  }

  if (type === StationOwnerType.ThirdParty) {
    return 'camera';
  }

  return 'station';
};

export const getStationsOwnerType = (stations: Station[]): StationOwnerType => {
  if (stations.length === 0 || _every(stations, ['op', 'a'])) {
    return StationOwnerType.Pano;
  }

  if (!_find(stations, ['op', 'a'])) {
    return StationOwnerType.ThirdParty;
  }

  return StationOwnerType.Mixed;
};

// Only hanwha cameras support optical zoom right now.
export const stationWithOpticalZoom = (station: Station): boolean => {
  return !!station?.zoom;
};

// Get thumbnail image URLs of a station.
export const getStationThumbnailPaths = (station: Station, scale = '1'): string[] => {
  if (!station) {
    return [];
  }
  const { id, lastSequence = [], dois } = station;
  const [maxSequence] = lastSequence;
  if (!maxSequence || !dois) {
    return [];
  }
  const images: string[] = [];
  for (let i: number = 0; i < dois; i++) {
    images.push(`${station.pano}/${id}/${scale}/${maxSequence}/${i}`);
  }

  return images;
};

// `rotationFrom`: starting time of the target range
// `rotationTo`: ending time of the target range
export const getPanoDataPath = (
  id: number,
  rotationFrom: number = 0,
  rotationTo?: number,
  isOpticalZoom = false,
): string => {
  const rotationFromSeconds: number = isOpticalZoom ? Math.floor(rotationFrom) : Math.floor(rotationFrom * 60);
  const rotationToSeconds: number = isOpticalZoom ? Math.ceil(rotationTo) : Math.ceil(rotationTo * 60);
  let path: string = `/ec/${id}/${isOpticalZoom ? 'zoom' : 'pano'}?from=${rotationFromSeconds}`;

  if (rotationTo) {
    path += `&to=${rotationToSeconds}`;
  }

  return path;
};

/**
 * Convert pano data to map
 * @note panoData is an array of [rotation, timestamp in seconds], we need to convert it to a map of [timestamp in minutes, panoData]
 * @param panoData
 */
export const panaData2Map = (panoData: PanoData[]): Map<number, PanoData> => {
  const panoMapData: Map<number, PanoData> = new Map<number, PanoData>();
  panoData.forEach((data: PanoData) => {
    panoMapData.set(Math.floor(data[1] / 60), data);
  });

  return panoMapData;
};

/**
 * Returns whether the station is or is not disabled
 * - Disabled means a status of 'Inactive' or 'assemble'
 */
export const getIsStatusDisabled = (status: string) => {
  return status === 'inactive' || status === 'assemble';
};
