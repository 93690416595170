import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import { PanoData, PanoImagesData } from 'types';
import { getPanoDataPath } from 'utils';

import Comparators from './Comparators';
import RangeFilter from './RangeFilter';
import SortedMap from './SortedMap';

export default class PanoImagesApiTier extends RangeFilter<PanoImagesData> {
  api: AxiosInstance;
  private _stationId: number;
  private _isOpticalZoom: boolean;
  private _range: number[] = [0, new Date('2100-01-01').getTime()];

  constructor(next: RangeFilter<PanoImagesData>, stationId: number, isOpticalZoom = false) {
    super(next);
    this._stationId = stationId;
    this._isOpticalZoom = isOpticalZoom;
  }

  protected getApi(): AxiosInstance {
    if (this.api) {
      return this.api;
    }
    this.api = authApi();

    return this.api;
  }

  protected range(): number[] {
    return this._range;
  }

  protected async select(from: number, to: number): Promise<Map<number, PanoImagesData>> {
    console.info('----------- API Call -> [' + from + ', ' + to + ') ---------------');

    // in data cache, key is in minutes
    // in API endpoint, parameters are in seconds
    const path: string = getPanoDataPath(this._stationId, from, to, this._isOpticalZoom);

    try {
      const { data: images } = await this.getApi().get(path);
      const results: SortedMap<number, PanoImagesData> = new SortedMap<number, PanoImagesData>(
        Comparators.compareNumber,
      );
      for (let index: number = 0; index < images.length; index++) {
        const image: PanoImagesData = images[index];
        if (image && image.length >= 2) {
          const [, timestamp] = image;
          const timestampKeyInSec: number = this._isOpticalZoom ? timestamp : Math.floor(timestamp / 60);
          results.set(timestampKeyInSec, image);
        }
      }

      return results;
    } catch (err) {
      console.error('[Pano] API Error', err);
      throw new Error('[Pano] API Error');
    }
  }

  protected insert(_from: number, _to: number, _data: Map<number, PanoData>): void {}
}
